export var TANDYM_CONSTANTS = {
    API_URLS: {
        LOGIN_LINK_VALIDATION_GET: "/Auth/ValidateClientLoginLink",
        GENERATE_LOGIN_LINK: "/Auth/GenerateClientLoginLink",
        ACCEPT_TERMS_POST: "/Auth/AcceptTermsForClient",
        NAJAXA_API: "/Najaxa/GenerateNajaxaTokenClient",
        CLIENT_PLACEMENTS_GET: "/Client/GetPlacementsForClient",
        CLIENT_ENTITLEMENTS_GET: "/Client/GetEntitlementsForClient",
        CLIENT_DASHBOARD_GET: "/Dashboard/GetDashboardByClient",
    },
};
