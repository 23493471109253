var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Divider, Grid, Link, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, } from '@mui/material';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import { CustomScrollContainer, placementsStyles, StyledTableCell, StyledTableRow, } from '../../pages/placements/Placements.styled';
import CustomPagination from '../../pages/placements/CustomPagination';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { formatDate } from '../../shared/utilities/utilities';
import { liveContractorDrillDownStyles, overViewStyles, } from '../../pages/Home/Overview/OverViewStyles';
import bannerImage from '../../images/openPositionsIIconCarouselBackgroundImg.png';
import { colorCodes } from '../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterDrawer from '../../shared/filter/FilterDrawer';
import { SectionTitle } from '../../shared/utilities/enumValues';
var OpenPositionsDrillDown = function (props) {
    var consultingSolutions = props.consultingSolutions, missionCoreSolutions = props.missionCoreSolutions, title = props.title, setDrillDownTitle = props.setDrillDownTitle;
    var rowsPerPage = 7;
    var _a = useState(0), page = _a[0], setPage = _a[1];
    var _b = useState([]), drillDownData = _b[0], setDrillDownData = _b[1];
    var _c = useState(0), positionRemaining = _c[0], setPositionRemaining = _c[1];
    var _d = useState(0), vacancies = _d[0], setVacancies = _d[1];
    var _e = useState(0), jobs = _e[0], setJobs = _e[1];
    var _f = useState({}), sortOrder = _f[0], setSortOrder = _f[1];
    var _g = useState(false), showFilter = _g[0], setShowFilter = _g[1];
    var _h = useState(''), selectedType = _h[0], setSelectedType = _h[1];
    var _j = useState(''), selectedClientName = _j[0], setSelectedClientName = _j[1];
    var _k = useState(''), selectedJobTitle = _k[0], setSelectedJobTitle = _k[1];
    var _l = useState([]), allTypes = _l[0], setAllTypes = _l[1];
    var _m = useState([]), allClientNames = _m[0], setAllClientNames = _m[1];
    var _o = useState([]), allJobTitles = _o[0], setAllJobTitles = _o[1];
    var _p = useState(null), startDateFrom = _p[0], setStartDateFrom = _p[1];
    var _q = useState(null), startDateTo = _q[0], setStartDateTo = _q[1];
    var isScreenWidth900 = useMediaQuery('(min-width:900px)');
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
    };
    useEffect(function () {
        if (props.drillDownData && props.drillDownData.length > 0) {
            var consolidatedDrillDownData = props.drillDownData
                .filter(function (item) {
                return Array.isArray(item.drillDownData) && item.drillDownData.length > 0;
            })
                .flatMap(function (item) {
                return item.drillDownData.map(function (drillDownItem) { return (__assign({}, drillDownItem)); });
            });
            setDrillDownData(consolidatedDrillDownData);
            setPositionRemaining(consolidatedDrillDownData.reduce(function (acc, curr) { return acc + curr.positionRemaining; }, 0));
            setVacancies(consolidatedDrillDownData.reduce(function (acc, curr) { return acc + curr.placements; }, 0));
            setJobs(consolidatedDrillDownData.length);
            var uniqueJobTitles_1 = new Set();
            var uniqueClientNames_1 = new Set();
            var uniqueTypes_1 = new Set();
            consolidatedDrillDownData.forEach(function (item) {
                uniqueJobTitles_1.add(item.jobTitle);
                uniqueClientNames_1.add(item.client);
                uniqueTypes_1.add(item.type);
            });
            setAllJobTitles(Array.from(uniqueJobTitles_1));
            setAllClientNames(Array.from(uniqueClientNames_1));
            setAllTypes(Array.from(uniqueTypes_1));
        }
    }, [props.drillDownData]);
    var columnKeyMap = {
        'First Go To Market Tag': 'groupName',
        Type: 'type',
        'Client Name': 'client',
        'Job Title': 'jobTitle',
        Positions: 'positions',
        Filled: 'filled',
        'Positions Remaining': 'positionRemaining',
        Submissions: 'submission',
        Placements: 'placements',
        'Created Date': 'vacancyDate',
    };
    var handleSort = function (columnDisplayName) {
        var columnKey = columnKeyMap[columnDisplayName]; // Get the key from the map
        var isAsc = sortOrder[columnKey] === 'asc';
        var order = isAsc ? 'desc' : 'asc';
        setSortOrder(function (prevSortOrder) {
            var _a;
            return (__assign(__assign({}, prevSortOrder), (_a = {}, _a[columnKey] = order, _a)));
        });
        var sortedData = __spreadArray([], drillDownData, true).sort(function (a, b) {
            var aValue = a[columnKey];
            var bValue = b[columnKey];
            if (aValue == null || bValue == null)
                return 0;
            if (aValue < bValue)
                return order === 'asc' ? -1 : 1;
            if (aValue > bValue)
                return order === 'asc' ? 1 : -1;
            return 0;
        });
        setDrillDownData(sortedData);
    };
    var filterHandler = function (name) {
        if (name === title) {
            return;
        }
        setDrillDownTitle(name);
        var combinedData = __spreadArray(__spreadArray([], consultingSolutions, true), missionCoreSolutions, true);
        var filteredData = combinedData.filter(function (item) { return item.name === name; })[0].drillDownData;
        setDrillDownData(filteredData);
        setPositionRemaining(filteredData.reduce(function (acc, curr) { return acc + curr.positionRemaining; }, 0));
        setJobs(filteredData.length);
        setPage(0);
    };
    var handleFilterApply = function () {
        var filteredData = drillDownData;
        if (selectedJobTitle) {
            filteredData = filteredData.filter(function (item) { return item.jobTitle === selectedJobTitle; });
        }
        if (selectedClientName) {
            filteredData = filteredData.filter(function (item) { return item.client === selectedClientName; });
        }
        if (selectedType) {
            filteredData = filteredData.filter(function (item) { return item.type === selectedType; });
        }
        if (startDateFrom) {
            filteredData = filteredData.filter(function (item) {
                return item.vacancyDate && new Date(item.vacancyDate) >= startDateFrom;
            });
        }
        if (startDateTo) {
            filteredData = filteredData.filter(function (item) {
                return item.vacancyDate && new Date(item.vacancyDate) <= startDateTo;
            });
        }
        setDrillDownData(filteredData);
        setPositionRemaining(filteredData.reduce(function (acc, curr) { return acc + curr.positionRemaining; }, 0));
        setJobs(filteredData.length);
        setShowFilter(false);
        setPage(0);
    };
    return (_jsxs(Grid, __assign({ sx: { mx: '28px', background: '#fff' } }, { children: [_jsx(Grid, __assign({ sx: [
                    overViewStyles.image,
                    {
                        backgroundImage: isScreenWidth900 ? "url(".concat(bannerImage, ")") : '',
                        borderBottom: '1px solid #EAECF0',
                    },
                ] }, { children: _jsxs(Grid, __assign({ container: true, sx: { padding: '20px' } }, { children: [_jsx(Grid, __assign({ item: true, sm: 4, md: 3, lg: 3 }, { children: _jsxs(Box, __assign({ id: 'section-1' }, { children: [_jsxs(Box, __assign({ className: 'header-name' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(14), sx: { mb: '10px' } }, { children: "Note - Click this back arrow to return to the home page." })), _jsx(Link, __assign({ underline: 'none', onClick: props.onBackClick, "data-testid": 'back-click', id: 'back-click', sx: { cursor: 'pointer' } }, { children: _jsx(TPSvgIcon, { resourceName: 'arrow-icon-left' }) })), _jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon' }), _jsxs(Typography, __assign({ sx: {
                                                    display: 'inline-block',
                                                    position: 'relative',
                                                    bottom: '3px',
                                                }, fontSize: fontSizeDynamic(24), "data-testid": 'open-position-drill-down-title' }, { children: ["My Open Jobs", ' '] })), props.title !== 'My Open Jobs' ? (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ sx: {
                                                            display: 'inline-block',
                                                            position: 'relative',
                                                            bottom: '3px',
                                                            px: '5px',
                                                        }, fontSize: fontSizeDynamic(24) }, { children: "/" })), _jsx(Typography, __assign({ sx: {
                                                            display: 'inline-block',
                                                            position: 'relative',
                                                            bottom: '3px',
                                                            color: colorCodes.iconBackgroundColor.royalBlue,
                                                        }, fontSize: fontSizeDynamic(24), "data-testid": 'open-position-drill-down-title' }, { children: props.title }))] })) : null] })), _jsx(Grid, __assign({ className: 'header-info', sx: { mt: '15px' } }, { children: _jsxs(Grid, __assign({ container: true, item: true }, { children: [_jsx(Grid, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: _jsx(TPSvgIcon, { resourceName: 'share-icon' }) })), _jsxs(Grid, __assign({ item: true, sx: { mx: '10px' } }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16) }, { children: "Positions Remaining" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(24), sx: {
                                                                color: colorCodes.iconBackgroundColor.royalBlue,
                                                                mr: '1rem',
                                                            } }, { children: positionRemaining }))] })), _jsx(Grid, __assign({ sx: {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        pl: '1rem',
                                                        borderLeft: '1px solid #E7E7E7',
                                                    } }, { children: _jsx(TPSvgIcon, { resourceName: 'breifcase-icon' }) })), _jsxs(Grid, __assign({ item: true, sx: { mx: '10px' } }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(16) }, { children: "Jobs" })), _jsx(Typography, __assign({ fontSize: fontSizeDynamic(24), sx: { color: colorCodes.iconBackgroundColor.royalBlue } }, { children: jobs }))] }))] })) }))] })) })), _jsx(Grid, __assign({ item: true, sm: 2, md: 1, lg: 1, sx: {
                                display: 'inline-flex',
                            } }, { children: _jsx(Divider, { sx: liveContractorDrillDownStyles.divider, orientation: 'vertical', variant: 'middle', flexItem: true }) })), _jsx(Grid, __assign({ item: true, sm: 6, md: 4, lg: 4 }, { children: _jsxs(Box, __assign({ id: 'section-2', sx: { paddingTop: '8px' } }, { children: [_jsxs(Box, __assign({ className: 'mission-core-solutions-header', sx: { display: 'flex', alignItems: 'flex-start', gap: '1rem' } }, { children: [_jsxs(Box, __assign({ className: 'functional-group-text' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), "data-testid": 'mission-core-solutions-text', id: 'mission-core-solutions-text', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Mission Core Solutions" })), _jsx(Typography, { fontSize: fontSizeDynamic(24), id: 'mission-core-solutions-count', "data-testid": 'mission-core-solutions-count' })] })), _jsx(Box, __assign({ sx: liveContractorDrillDownStyles.filterBoxes }, { children: missionCoreSolutions === null || missionCoreSolutions === void 0 ? void 0 : missionCoreSolutions.map(function (item, index) {
                                                    var isTitleMatch = title === item.name;
                                                    return (_jsx(Box, __assign({ className: 'mission-core-solutions-circle', fontSize: fontSizeDynamic(14), sx: __assign(__assign({}, liveContractorDrillDownStyles.functionalGroupCircle), { backgroundColor: isTitleMatch
                                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                                : '', color: isTitleMatch
                                                                ? colorCodes.backgroundColors.white
                                                                : '', borderColor: isTitleMatch
                                                                ? colorCodes.borderColor.QuillGray
                                                                : '', cursor: 'pointer' }), onClick: function () { return filterHandler(item.name); } }, { children: item.name }), 'missioncore-solutions-' + index));
                                                }) }))] })), _jsxs(Box, __assign({ className: 'consulting-solutions-header', sx: liveContractorDrillDownStyles.consultingSolutionsHeader }, { children: [_jsxs(Box, __assign({ className: 'functional-group-text' }, { children: [_jsx(Typography, __assign({ fontSize: fontSizeDynamic(12), "data-testid": 'consulting-solutions-text', id: 'consulting-solutions-text', sx: liveContractorDrillDownStyles.functionalGroupName }, { children: "Consulting Solutions" })), _jsx(Typography, { fontSize: fontSizeDynamic(24), id: 'consulting-solutions-count', "data-testid": 'consulting-solutions-count' })] })), _jsx(Box, __assign({ sx: liveContractorDrillDownStyles.filterBoxes }, { children: consultingSolutions === null || consultingSolutions === void 0 ? void 0 : consultingSolutions.map(function (item, index) {
                                                    var isTitleMatch = title === item.name;
                                                    return (_jsx(Box, __assign({ className: 'consulting-solutions-circle', sx: __assign(__assign({}, liveContractorDrillDownStyles.functionalGroupCircle), { backgroundColor: isTitleMatch
                                                                ? colorCodes.iconBackgroundColor.royalBlue
                                                                : '', color: isTitleMatch
                                                                ? colorCodes.backgroundColors.white
                                                                : '', borderColor: isTitleMatch
                                                                ? colorCodes.borderColor.QuillGray
                                                                : '', cursor: 'pointer' }), onClick: function () { return filterHandler(item.name); }, fontSize: fontSizeDynamic(14) }, { children: item.name }), 'consulting-solutions-' + index));
                                                }) }))] }))] })) }))] })) })), _jsx(Grid, { container: true, sx: { my: '1rem' }, className: 'drill-down-header' }), _jsxs(Grid, { children: [_jsx(TableContainer, __assign({ component: Paper, sx: { maxHeight: 440, overflowX: 'auto' } }, { children: _jsx(CustomScrollContainer, { children: _jsxs(Table, __assign({ stickyHeader: true }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ sx: placementsStyles.stickyColumCandidate }, { children: _jsxs(Stack, __assign({ id: 'candidate-header-First-Go-To-Market-Tag', "data-testid": 'candidate-header-First-Go-To-Market-Tag', direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort('First Go To Market Tag'); } }, { children: [_jsx(Typography, { children: "FIRST GO TO MARKET TAG" }), _jsx(TPSvgIcon, { resourceName: 'sort-icon' })] })) })), [
                                                    'Type',
                                                    'Client Name',
                                                    'Job Title',
                                                    'Positions',
                                                    'Filled',
                                                    'Positions Remaining',
                                                    'Submissions',
                                                    'Placements',
                                                ].map(function (columnDisplayName) { return (_jsx(StyledTableCell, { children: _jsxs(Stack, __assign({ id: columnDisplayName, "data-testid": columnDisplayName, direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort(columnDisplayName); } }, { children: [_jsx(Typography, { children: columnDisplayName.toUpperCase() }), _jsx(TPSvgIcon, { resourceName: 'sort-icon', id: "sort-icon-".concat(columnDisplayName), "data-testid": "sort-icon-".concat(columnDisplayName) })] })) }, columnDisplayName)); }), _jsx(TableCell, __assign({ sx: placementsStyles.stickyColumEmail }, { children: _jsxs(Stack, __assign({ id: 'candidate-header-Vacancy-Date', "data-testid": 'candidate-header-Vacancy-Date', direction: 'row', spacing: 1, alignItems: 'center', sx: { cursor: 'pointer' }, onClick: function () { return handleSort('Created Date'); } }, { children: [_jsx(Typography, { children: "Created Date" }), _jsx(TPSvgIcon, { resourceName: 'sort-icon' }), _jsx(FilterAltOutlinedIcon, { sx: { color: colorCodes.iconBackgroundColor.royalBlue }, onClick: function () { return setShowFilter(true); } })] })) }))] }) }), _jsx(TableBody, { children: (drillDownData === null || drillDownData === void 0 ? void 0 : drillDownData.length) > 0 ? (drillDownData
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map(function (drillDownDataItem, index) { return (_jsxs(StyledTableRow, { children: [_jsx(TableCell, __assign({ sx: placementsStyles.stickyColumCandidate }, { children: drillDownDataItem.groupName })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.type })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.client })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.jobTitle })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.positions })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.filled })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.positionRemaining })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.submission })), _jsx(TableCell, __assign({ sx: placementsStyles.tableCellCustom }, { children: drillDownDataItem.placements })), _jsx(TableCell, __assign({ sx: placementsStyles.stickyColumEmail }, { children: formatDate(drillDownDataItem.vacancyDate) }))] }, index)); })) : (_jsx(StyledTableRow, { children: _jsx(TableCell, __assign({ colSpan: 11, align: 'center' }, { children: "No data found" })) })) })] })) }) })), _jsx(CustomPagination, { count: drillDownData.length, page: page, rowsPerPage: rowsPerPage, onPageChange: handleChangePage })] }), showFilter && (_jsx(FilterDrawer, { title: SectionTitle.MyOpenPositions, onClose: function () { return setShowFilter(false); }, open: showFilter, onApply: handleFilterApply, startDateFrom: startDateFrom, setStartDateFrom: setStartDateFrom, startDateTo: startDateTo, setStartDateTo: setStartDateTo, fields: [
                    {
                        type: 'dropdown',
                        label: 'Type',
                        options: allTypes,
                        selectedOption: selectedType,
                        onChange: function (selectedOption) {
                            return setSelectedType(selectedOption);
                        },
                    },
                    {
                        type: 'dropdown',
                        label: 'Client Name',
                        options: allClientNames,
                        selectedOption: selectedClientName,
                        onChange: function (selectedOption) {
                            return setSelectedClientName(selectedOption);
                        },
                    },
                    {
                        type: 'dropdown',
                        label: 'Job Title',
                        options: allJobTitles,
                        selectedOption: selectedJobTitle,
                        onChange: function (selectedOption) {
                            return setSelectedJobTitle(selectedOption);
                        },
                    },
                ] }))] })));
};
export default OpenPositionsDrillDown;
