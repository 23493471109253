export var MockOperationalMetrices = {
    operationalMetricsLanding: {
        recruitmentRatioMetrics: {
            fillRate: 45.5,
            data: [
                {
                    label: 'Positions',
                    value: 2177,
                    ratio: '1:6.4',
                },
                {
                    label: 'Submitted',
                    value: 14000,
                    ratio: '3.2:1',
                },
                {
                    label: 'Interviewed',
                    value: 4319,
                    ratio: '1.9:1',
                },
                {
                    label: 'Offered',
                    value: 2257,
                    ratio: '9:1',
                },
                {
                    label: 'Accepted',
                    value: 2177,
                    ratio: null,
                },
            ],
        },
        recruitmentTimelineMetrics: [
            {
                label: 'Select',
                value: 2,
            },
            {
                label: 'Submit',
                value: 4,
            },
            {
                label: 'Interview',
                value: 10,
            },
            {
                label: 'Offer',
                value: 12,
            },
            {
                label: 'Accept',
                value: 14,
            },
            {
                label: 'Placed',
                value: 16,
            },
            {
                label: 'Start',
                value: 0,
            },
            {
                label: 'Total',
                value: 22,
            },
        ],
        contractorsOnBillingMetrics: {
            data: [
                {
                    label: 'Nov 2023',
                    value: 3,
                    drillDownData: {
                        month: 'Nov 2023',
                        data: [
                            {
                                id: 1,
                                candidateName: 'Abu White',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'Test Project Manager',
                                consultant: 'Makayla Ledford',
                                startDate: '2024-01-25T00:00:00Z',
                                endDate: '2026-10-25T00:00:00Z',
                            },
                            {
                                id: 2,
                                candidateName: 'Alekha Gada',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'IT Manager',
                                consultant: 'Sebastian Restrepo',
                                startDate: '2024-09-12T00:00:00Z',
                                endDate: '2026-01-31T00:00:00Z',
                            },
                            {
                                id: 3,
                                candidateName: 'Test Candidate',
                                client: 'Mercury',
                                functionalGroup: 'Program & Project Management',
                                jobTitle: 'Testing',
                                consultant: 'Mercury Support',
                                startDate: '2024-01-17T00:00:00Z',
                                endDate: '2025-02-03T00:00:00Z',
                            },
                        ],
                    },
                },
                {
                    label: 'Dec 2023',
                    value: 2,
                    drillDownData: {
                        month: 'Dec 2023',
                        data: [
                            {
                                id: 1,
                                candidateName: 'Abu White',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'Test Project Manager',
                                consultant: 'Makayla Ledford',
                                startDate: '2024-01-25T00:00:00Z',
                                endDate: '2026-10-25T00:00:00Z',
                            },
                            {
                                id: 2,
                                candidateName: 'Alekha Gada',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'IT Manager',
                                consultant: 'Sebastian Restrepo',
                                startDate: '2024-09-12T00:00:00Z',
                                endDate: '2026-01-31T00:00:00Z',
                            },
                        ],
                    },
                },
                {
                    label: 'Jan 2024',
                    value: 0,
                    drillDownData: {
                        month: null,
                        data: [],
                    },
                },
                {
                    label: 'Feb 2024',
                    value: 1,
                    drillDownData: {
                        month: 'Feb 2024',
                        data: [
                            {
                                id: 1,
                                candidateName: 'Abu White',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'Test Project Manager',
                                consultant: 'Makayla Ledford',
                                startDate: '2024-01-25T00:00:00Z',
                                endDate: '2026-10-25T00:00:00Z',
                            },
                        ],
                    },
                },
                {
                    label: 'Mar 2024',
                    value: 2,
                    drillDownData: {
                        month: 'Mar 2024',
                        data: [
                            {
                                id: 1,
                                candidateName: 'Abu White',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'Test Project Manager',
                                consultant: 'Makayla Ledford',
                                startDate: '2024-01-25T00:00:00Z',
                                endDate: '2026-10-25T00:00:00Z',
                            },
                            {
                                id: 2,
                                candidateName: 'Alekha Gada',
                                client: 'Amazon',
                                functionalGroup: 'Data Center',
                                jobTitle: 'IT Manager',
                                consultant: 'Sebastian Restrepo',
                                startDate: '2024-09-12T00:00:00Z',
                                endDate: '2026-01-31T00:00:00Z',
                            },
                        ],
                    },
                },
                {
                    label: 'Apr 2024',
                    value: 0,
                    drillDownData: {
                        month: null,
                        data: [],
                    },
                },
            ],
        },
        placedPositionsMetrics: {
            data: [
                {
                    monthCreated: 'Nov 2023',
                    positions: 2,
                    placements: 1,
                    filledPercentage: 50,
                },
                {
                    monthCreated: 'Dec 2023',
                    positions: 1,
                    placements: 1,
                    filledPercentage: 100,
                },
                {
                    monthCreated: 'Jan 2024',
                    positions: 4,
                    placements: 1,
                    filledPercentage: 25,
                },
                {
                    monthCreated: 'Feb 2024',
                    positions: 6,
                    placements: 5,
                    filledPercentage: 83.3,
                },
                {
                    monthCreated: 'Mar 2024',
                    positions: 5,
                    placements: 1,
                    filledPercentage: 20,
                },
                {
                    monthCreated: 'Apr 2024',
                    positions: 2,
                    placements: 1,
                    filledPercentage: 50,
                },
            ],
        },
    },
    recruitementTimelineZoomIn: {
        clientDataMetrics: [
            {
                label: 'Select',
                value: 2,
            },
            {
                label: 'Submit',
                value: 4,
            },
            {
                label: 'Interview',
                value: 4,
            },
            {
                label: 'Offer',
                value: 3,
            },
            {
                label: 'Accept',
                value: 3,
            },
            {
                label: 'Placed',
                value: 4,
            },
            {
                label: 'Start',
                value: 0,
            },
            {
                label: 'Total',
                value: 20,
            },
        ],
        tandymDataMetrics: [
            {
                label: 'Select',
                value: 4,
            },
            {
                label: 'Submit',
                value: 6,
            },
            {
                label: 'Interview',
                value: 4,
            },
            {
                label: 'Offer',
                value: 6,
            },
            {
                label: 'Accept',
                value: 5,
            },
            {
                label: 'Placed',
                value: 4,
            },
            {
                label: 'Start',
                value: 0,
            },
            {
                label: 'Total',
                value: 29,
            },
        ],
        recruitmentTimelineStepsMetrics: {
            kpi: [
                'Select',
                'Submit',
                'Interview',
                'Offer',
                'Accept',
                'Start',
                'TOTAL',
            ],
            selectedClient: {
                Select: 7.8,
                Submit: 0.6,
                Interview: 13.2,
                Offer: 4.2,
                Accept: 0.2,
                Start: 21.6,
                TOTAL: 47.5,
            },
            deltaFromTandymAvg: {
                Select: -0.1,
                Submit: -0.7,
                Interview: 1.7,
                Offer: -4.3,
                Accept: -0.9,
                Start: 6.6,
                TOTAL: 2.2,
            },
            tandymGTMAvg: {
                Select: 7.9,
                Submit: 1.3,
                Interview: 11.5,
                Offer: 8.6,
                Accept: 1.1,
                Start: 15,
                TOTAL: 45.3,
            },
        },
    },
};
