var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Grid, Typography } from '@mui/material';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
// Function to determine the color based on the value for delta cells
var getColor = function (value, rowType) {
    if (rowType === 'deltaFromTandymAvg' && typeof value === 'number') {
        if (value < 0)
            return '#FF8A8A'; // Green for negative values
        if (value > 0)
            return '#D5ED9F'; // Red for positive values
    }
    return '#ffffff'; // Default white for other values
};
// Main component to render the recruitment timeline
var RecruitmentTimelineSteps = function (props) {
    var recruitmentTimelineSteps = props.recruitmentTimelineSteps;
    var chartOptions = {
        chart: {
            type: 'column',
            backgroundColor: '#000000', // Black background
        },
        credits: {
            enabled: false, // Removes the Highcharts watermark
        },
        title: {
            text: 'Recruitment Timeline - Number of Days for each Recruitment Step',
            style: {
                color: '#ffffff',
                fontSize: fontSizeDynamic(18),
            },
        },
        xAxis: {
            categories: recruitmentTimelineSteps.kpi,
            lineColor: '#ffffff',
            labels: {
                style: {
                    color: '#ffffff',
                    fontWeight: 'bold',
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: null,
            },
            gridLineColor: '#444444',
            labels: {
                style: {
                    color: '#ffffff',
                },
            },
        },
        plotOptions: {
            waterfall: {
                borderRadius: 0,
                borderWidth: 0, // Removes the border color
            },
            column: {
                dataLabels: {
                    enabled: true,
                    color: '#ffffff',
                    style: {
                        textOutline: 'none',
                        fontWeight: 'bold',
                    },
                },
                borderWidth: 0,
                borderRadius: 0,
            },
        },
        legend: {
            itemStyle: {
                color: '#ffffff',
            },
        },
        tooltip: {
            shared: true,
            backgroundColor: '#333333',
            borderColor: '#ffffff',
            style: {
                color: '#ffffff',
            },
        },
        series: [
            {
                name: 'Selected Client',
                data: Object.values(recruitmentTimelineSteps.selectedClient),
                color: '#9DBDFF',
                type: 'column',
            },
            {
                name: 'Delta from Tandym Avg',
                data: Object.values(recruitmentTimelineSteps.deltaFromTandymAvg),
                color: '#55679C',
                type: 'column',
            },
            {
                name: 'Tandym GTM Average',
                data: Object.values(recruitmentTimelineSteps.tandymGTMAvg),
                color: '#1E2A5E',
                type: 'column',
            },
        ],
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: {
                    display: 'grid',
                    gap: '2px',
                    backgroundColor: '#000000',
                    padding: '20px',
                    color: '#ffffff',
                } }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: {
                                    backgroundColor: '#444444',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '60px',
                                } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#ffffff' } }, { children: "KPI" })) })), recruitmentTimelineSteps.kpi.map(function (label, index) { return (_jsx(Grid, __assign({ item: true, xs: index === 6 ? 1.5 : 1, sx: {
                                    backgroundColor: '#cccccc',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '60px',
                                    marginRight: index === 5 ? '10px' : '5px', // Larger gap before "TOTAL"
                                } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold' } }, { children: label })) }), index)); })] })), Object.entries(recruitmentTimelineSteps).map(function (_a, rowIndex) {
                        var key = _a[0], values = _a[1];
                        if (key === 'kpi')
                            return null; // Skip the KPI row as it's already rendered
                        return (_jsxs(React.Fragment, { children: [_jsxs(Grid, __assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, __assign({ item: true, xs: 2, sx: {
                                                backgroundColor: '#000000',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '60px',
                                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#ffffff' } }, { children: key })) })), recruitmentTimelineSteps.kpi.map(function (label, index) { return (_jsx(Grid, __assign({ item: true, xs: index === 6 ? 1.5 : 1, sx: {
                                                backgroundColor: getColor(values[label], key),
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '60px',
                                                marginRight: index === 5 ? '10px' : '5px', // Larger gap before "TOTAL"
                                            } }, { children: _jsx(Typography, __assign({ variant: 'subtitle1', style: { fontWeight: 'bold', color: '#000000' } }, { children: values[label] })) }), index)); })] })), key === 'Delta from Tandym Avg' && (_jsx(Box, { sx: {
                                        borderBottom: '1px solid #ffffff',
                                        marginTop: '5px',
                                        marginBottom: '22px',
                                    } }))] }, rowIndex));
                    })] })), _jsx(Box, __assign({ sx: {
                    display: 'grid',
                    gap: '2px',
                    backgroundColor: '#000000',
                    padding: '20px',
                    color: '#ffffff',
                    mt: 4,
                } }, { children: _jsx(HighchartsReact, { highcharts: Highcharts, options: chartOptions }) }))] }));
};
export default RecruitmentTimelineSteps;
