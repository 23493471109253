var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import FirstPageSharpIcon from "@mui/icons-material/FirstPageSharp";
import NavigateBeforeSharpIcon from "@mui/icons-material/NavigateBeforeSharp";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import LastPageSharpIcon from "@mui/icons-material/LastPageSharp";
var CustomPaginationStyles = {
    root: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 2,
    },
    leftSection: {
        fontSize: "".concat((window.innerWidth / 1920) * (14 / 16), "rem"),
        lineHeight: "".concat((window.innerWidth / 1920) * (19.7 / 16), "rem"),
        fontWeight: 400,
        color: "#707070",
    },
    pageNumbers: {
        display: "flex",
        alignItems: "center",
        gap: "7px",
    },
    pageButton: {
        borderRadius: "0",
        border: "0.93px solid #eaeaea",
        minWidth: "28px",
        height: "28px",
        padding: "0",
    },
    ellipsis: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "0",
        border: "0.93px solid #eaeaea",
        minWidth: "28px",
        height: "28px",
        padding: "0",
    },
    zeroPadding: {
        padding: "0",
    },
};
var CustomPagination = function (_a) {
    var count = _a.count, page = _a.page, rowsPerPage = _a.rowsPerPage, onPageChange = _a.onPageChange;
    var totalPages = Math.ceil(count / rowsPerPage);
    var renderPageNumbers = function () {
        var pageNumbers = [];
        var startPage = Math.max(1, Math.min(page + 1, totalPages - 3));
        for (var i = startPage; i < Math.min(startPage + 4, totalPages + 1); i++) {
            pageNumbers.push(i);
        }
        return (_jsxs(_Fragment, { children: [startPage > 1 && (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ onClick: function (e) { return onPageChange(e, 0); }, sx: CustomPaginationStyles.pageButton }, { children: "1" })), startPage > 2 && (_jsx(Box, __assign({ sx: CustomPaginationStyles.ellipsis }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "..." })) })))] })), pageNumbers.map(function (number) { return (_jsx(Button, __assign({ onClick: function (e) { return onPageChange(e, number - 1); }, sx: {
                        backgroundColor: page === number - 1 ? "#2a70ea" : "inherit",
                        color: page === number - 1 ? "#fff" : "inherit",
                        fontWeight: page === number - 1 ? "bold" : "normal",
                        fontSize: "".concat((window.innerWidth / 1920) * (14.93 / 16), "rem"),
                        lineHeight: "".concat((window.innerWidth / 1920) * (20.34 / 16), "rem"),
                        borderRadius: "0",
                        border: "0.93px solid #eaeaea",
                        minWidth: "28px",
                        height: "28px",
                        "&:hover": {
                            backgroundColor: page === number - 1 ? "#1a5cbb" : "inherit",
                        },
                    } }, { children: number }), number)); }), startPage + 3 < totalPages && (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: CustomPaginationStyles.ellipsis }, { children: _jsx(Typography, __assign({ variant: "body2" }, { children: "..." })) })), _jsx(Button, __assign({ onClick: function (e) { return onPageChange(e, totalPages - 1); }, sx: CustomPaginationStyles.pageButton }, { children: totalPages }))] }))] }));
    };
    return (_jsxs(Box, __assign({ sx: CustomPaginationStyles.root }, { children: [_jsxs(Typography, __assign({ variant: "body2", sx: CustomPaginationStyles.leftSection }, { children: [page + 1, " of ", totalPages, " Pages (", count, " Records)"] })), _jsxs(Box, __assign({ sx: CustomPaginationStyles.pageNumbers }, { children: [_jsx(IconButton, __assign({ onClick: function (e) { return onPageChange(e, 0); }, disabled: page === 0, "aria-label": "first page", sx: __assign(__assign({}, CustomPaginationStyles.zeroPadding), { "&:hover": {
                                backgroundColor: page === 0 ? "inherit" : "transparent",
                            } }) }, { children: _jsx(FirstPageSharpIcon, { sx: CustomPaginationStyles.pageButton }) })), _jsx(IconButton, __assign({ onClick: function (e) { return onPageChange(e, Math.max(page - 1, 0)); }, disabled: page === 0, "aria-label": "previous page", sx: __assign(__assign({}, CustomPaginationStyles.zeroPadding), { "&:hover": {
                                backgroundColor: page === 0 ? "inherit" : "transparent",
                            } }) }, { children: _jsx(NavigateBeforeSharpIcon, { sx: CustomPaginationStyles.pageButton }) })), renderPageNumbers(), _jsx(IconButton, __assign({ onClick: function (e) { return onPageChange(e, Math.min(page + 1, totalPages - 1)); }, disabled: page >= totalPages - 1, "aria-label": "next page", sx: __assign(__assign({}, CustomPaginationStyles.zeroPadding), { "&:hover": {
                                backgroundColor: page >= totalPages - 1 ? "inherit" : "transparent",
                            } }) }, { children: _jsx(NavigateNextSharpIcon, { sx: CustomPaginationStyles.pageButton }) })), _jsx(IconButton, __assign({ onClick: function (e) { return onPageChange(e, totalPages - 1); }, disabled: page >= totalPages - 1, "aria-label": "last page", sx: __assign(__assign({}, CustomPaginationStyles.zeroPadding), { "&:hover": {
                                backgroundColor: page >= totalPages - 1 ? "inherit" : "transparent",
                            } }) }, { children: _jsx(LastPageSharpIcon, { sx: CustomPaginationStyles.pageButton }) }))] }))] })));
};
export default CustomPagination;
