var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import TPCustomModal from '../../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import TPProgressModal from '../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import MyDocument from '../../../../tandym-web-common/src/components/pdf/MyPDF';
import classes from './Home.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../../tandym-web-common/src/shared/services/api/API';
import { setLogIn } from '../../store/authSlice';
import { useClientAppDispatch, useClientAppSelector } from '../../store/hook';
import OperationalMetrics from './OperationalMetrics/OperationalMetrics';
import OverView from './Overview/OverView';
import CommonTabs from '../../shared/Tabs/CustomTabs';
import { TANDYM_CONSTANTS } from '../../shared/Constants';
import { setEntitlements } from '../../store/entitlementSlice';
import { jwtDecode } from 'jwt-decode';
import LiveContractorDrillDown from '../../components/drill-down/LiveContractorDrillDown';
import OpenPositionsDrillDown from '../../components/drill-down/OpenPositionsDrillDown';
import { solutions } from '../../shared/utilities/enumValues';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Home = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var _l = useState(false), isLoading = _l[0], setIsLoading = _l[1];
    var _m = useState(false), checkbox = _m[0], setCheckbox = _m[1];
    var _o = useState(''), validateLink = _o[0], setValidateLink = _o[1];
    var _p = useState(false), isTermsAccepted = _p[0], setIsTermsAccepted = _p[1];
    var _q = useState(null), firstName = _q[0], setFirstName = _q[1];
    var _r = useState(null), lastName = _r[0], setLastName = _r[1];
    var _s = useState(null), clientName = _s[0], setClientName = _s[1];
    var _t = useState(null), dashboardData = _t[0], setDashboardData = _t[1];
    var location = useLocation();
    var _u = useAPI(), httpGet = _u.httpGet, httpPost = _u.httpPost;
    var navigate = useNavigate();
    var dispatch = useClientAppDispatch();
    var hasFetched = useRef(false);
    var email = useClientAppSelector(function (state) { return state.userAuth; }).email;
    var _v = useClientAppSelector(function (state) { return state.userAuth; }), candidateId = _v.candidateId, isLoggedIn = _v.isLoggedIn;
    var validateURL = props.validateURL, acceptTermsURL = props.acceptTermsURL;
    var _w = useState(false), drillDownVisible = _w[0], setDrillDownVisible = _w[1];
    var _x = useState(false), liveContractorDrillDownVisible = _x[0], setLiveContractorDrillDownVisible = _x[1];
    var _y = useState(''), drillDownTitle = _y[0], setDrillDownTitle = _y[1];
    var _z = useState([]), drillDownData = _z[0], setDrillDownData = _z[1];
    var _0 = useState([]), callForActionsData = _0[0], setCallForActionsData = _0[1];
    var _1 = React.useState(), activePositionData = _1[0], setActivePositionData = _1[1];
    var _2 = React.useState(), liveContractorData = _2[0], setLiveContractorData = _2[1];
    var _3 = useState([]), topTenPlacementsList = _3[0], setTopTenPlacementsList = _3[1];
    var _4 = useState([]), availableTandymPositions = _4[0], setAvailableTandymPositions = _4[1];
    var _5 = useState([]), topTenVacanciesList = _5[0], setTopTenVacanciesList = _5[1];
    var authToken = sessionStorage.getItem('authToken');
    var avtarNameValidation = function (firstName, lastName) {
        var firstNameValidation = (firstName === null || firstName === void 0 ? void 0 : firstName.trim().charAt(0)) || '';
        var lastNameValidation = (lastName === null || lastName === void 0 ? void 0 : lastName.trim().charAt(0)) || '';
        if (firstName && lastName) {
            return "".concat(firstNameValidation).concat(lastNameValidation);
        }
        else if (firstName) {
            return "".concat(firstNameValidation);
        }
        return '';
    };
    sessionStorage.setItem('userInitials', avtarNameValidation(firstName, lastName));
    var fetchDashBoardData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    _h.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CLIENT_DASHBOARD_GET, {
                            clientId: candidateId,
                        }, true)];
                case 1:
                    response = _h.sent();
                    if (!response.error) {
                        setDashboardData(response === null || response === void 0 ? void 0 : response.dashboard);
                        setTopTenPlacementsList((_a = response === null || response === void 0 ? void 0 : response.dashboard) === null || _a === void 0 ? void 0 : _a.top10Placements);
                        setTopTenVacanciesList((_b = response === null || response === void 0 ? void 0 : response.dashboard) === null || _b === void 0 ? void 0 : _b.top10Vacancy);
                        setAvailableTandymPositions((_d = (_c = response === null || response === void 0 ? void 0 : response.dashboard) === null || _c === void 0 ? void 0 : _c.activePostions) === null || _d === void 0 ? void 0 : _d.availablePositions);
                        setActivePositionData((_e = response === null || response === void 0 ? void 0 : response.dashboard) === null || _e === void 0 ? void 0 : _e.activePostions);
                        setLiveContractorData((_f = response === null || response === void 0 ? void 0 : response.dashboard) === null || _f === void 0 ? void 0 : _f.liveContractor);
                        setCallForActionsData((_g = response === null || response === void 0 ? void 0 : response.dashboard) === null || _g === void 0 ? void 0 : _g.callForActions);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_1 = _h.sent();
                    console.error('Fetch client placements info error:', error_1);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        isLoggedIn && fetchDashBoardData();
    }, [isLoggedIn]);
    var LCConsultingSolutions = (_b = (_a = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.liveContractor) === null || _a === void 0 ? void 0 : _a.liveContractorList) === null || _b === void 0 ? void 0 : _b.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.ConsultingSolutions; });
    var LCMissionCoreSolutions = (_d = (_c = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.liveContractor) === null || _c === void 0 ? void 0 : _c.liveContractorList) === null || _d === void 0 ? void 0 : _d.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.MissionCoreSolutions; });
    var OPConsultingSolutions = (_f = (_e = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.activePostions) === null || _e === void 0 ? void 0 : _e.activePositionList) === null || _f === void 0 ? void 0 : _f.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.ConsultingSolutions; });
    var OPMissionCoreSolutions = (_h = (_g = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.activePostions) === null || _g === void 0 ? void 0 : _g.activePositionList) === null || _h === void 0 ? void 0 : _h.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.solution) === solutions.MissionCoreSolutions; });
    useEffect(function () {
        var _a, _b, _c;
        if (authToken && typeof authToken === 'string') {
            try {
                var decodedToken = jwtDecode(authToken);
                setFirstName((_a = decodedToken.FirstName) !== null && _a !== void 0 ? _a : null);
                setLastName((_b = decodedToken.LastName) !== null && _b !== void 0 ? _b : null);
                setClientName((_c = decodedToken.ClientName) !== null && _c !== void 0 ? _c : null);
            }
            catch (error) {
                console.error('Invalid token:', error);
            }
        }
        else {
            console.error('Invalid authToken:', authToken);
        }
    }, [authToken]);
    useEffect(function () {
        var params = new URLSearchParams(location.search);
        var tokenFromUrl = params.get('token') || '';
        if (tokenFromUrl && !hasFetched.current) {
            hasFetched.current = true;
            setValidateLink(tokenFromUrl);
            loginLinkValidation(tokenFromUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    var loginLinkValidation = function (loginLink) { return __awaiter(void 0, void 0, void 0, function () {
        var response, url, parsedUrl, cleanUrl, _a, Email, FirstName, LastName, error_2;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(validateURL, {
                            code: loginLink,
                        })];
                case 1:
                    response = _c.sent();
                    if (response.error === false) {
                        url = (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.href;
                        parsedUrl = new URL(url);
                        parsedUrl.search = '';
                        cleanUrl = parsedUrl.toString();
                        window.history.pushState({}, '', cleanUrl);
                        if (!response.latestTermsAccepted) {
                            setIsTermsAccepted(true);
                        }
                        else {
                            setIsLoading(false);
                            _a = jwtDecode(response.authToken), Email = _a.Email, FirstName = _a.FirstName, LastName = _a.LastName;
                            dispatch(setLogIn({
                                candidateId: response.mercuryContactId,
                                isLoggedIn: true,
                                email: Email,
                                authToken: response.authToken,
                                isEmailLinkExpired: false,
                                firstName: FirstName,
                                lastName: LastName,
                            }));
                            // Optionally store authToken in session storage
                            sessionStorage.setItem('authToken', response.authToken);
                            fetchEntitlements();
                        }
                    }
                    else if (response.error === false &&
                        response.latestTermsAccepted === true) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoading(false);
                        if (response.message === 'Login Code has expired') {
                            dispatch(setLogIn({
                                candidateId: '',
                                isLoggedIn: false,
                                email: email,
                                isEmailLinkExpired: true,
                            }));
                        }
                        navigate('/');
                        hasFetched.current = false;
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _c.sent();
                    navigate('/');
                    hasFetched.current = false;
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleAcceptTerms = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, Email, FirstName, LastName, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!checkbox) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, httpPost(acceptTermsURL, null, {
                            code: validateLink,
                        })];
                case 2:
                    response = _b.sent();
                    if (response.error === false && response.latestTermsAccepted) {
                        setIsLoading(false);
                        setIsTermsAccepted(false);
                        _a = jwtDecode(response.authToken), Email = _a.Email, FirstName = _a.FirstName, LastName = _a.LastName;
                        dispatch(setLogIn({
                            candidateId: response.mercuryContactId,
                            isLoggedIn: true,
                            isEmailLinkExpired: false,
                            authToken: response.authToken,
                            email: Email,
                            firstName: FirstName,
                            lastName: LastName,
                        }));
                        // Optionally store authToken in session storage
                        sessionStorage.setItem('authToken', response.authToken);
                        setTimeout(function () {
                            fetchEntitlements();
                        }, 500);
                    }
                    else {
                        navigate('/');
                        throw new Error(response.message || 'Login link is not valid');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _b.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchEntitlements = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CLIENT_ENTITLEMENTS_GET)];
                case 1:
                    response = _a.sent();
                    if (!response.error) {
                        dispatch(setEntitlements({
                            entitlementsData: response,
                        }));
                        sessionStorage.setItem('timeSheetEntitlements', response.entitlementDto.timesheets);
                    }
                    else {
                        navigate('/');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_4 = _a.sent();
                    console.error('Fetch entitlements error:', error_4);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleArrowClick = function (mainTitle, title, data) {
        if (mainTitle === 'My Live Contractors') {
            setLiveContractorDrillDownVisible(true);
        }
        else if (mainTitle === 'My Open Jobs') {
            setDrillDownVisible(true);
        }
        setDrillDownTitle(title);
        setDrillDownData(data);
    };
    var handleBackClick = function () {
        setDrillDownVisible(false);
        setLiveContractorDrillDownVisible(false);
    };
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var tabs = [
        {
            label: 'Overview',
            component: (_jsx(OverView, { onArrowClick: function (mainTitle, title, data) {
                    return handleArrowClick(mainTitle, title, data);
                }, firstName: firstName, lastName: lastName, clientName: clientName, activePositionData: activePositionData !== null && activePositionData !== void 0 ? activePositionData : undefined, liveContractorData: liveContractorData !== null && liveContractorData !== void 0 ? liveContractorData : undefined, isLoading: false, topTenVacanciesList: topTenVacanciesList, availableTandymPositions: availableTandymPositions, topTenPlacementsList: topTenPlacementsList, callForActionsData: callForActionsData })),
            show: true,
        },
        {
            label: 'Operational Metrics',
            component: _jsx(OperationalMetrics, {}),
            show: (_j = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _j === void 0 ? void 0 : _j.showOperationalMetrics,
        },
    ];
    return (_jsx(Grid, __assign({ container: true }, { children: _jsx(Box, __assign({ sx: { width: '100%', mt: '34.4px' }, id: 'home', "data-testid": 'home' }, { children: drillDownVisible ? (_jsx(OpenPositionsDrillDown, { onBackClick: handleBackClick, title: drillDownTitle, drillDownData: drillDownData, consultingSolutions: OPConsultingSolutions, missionCoreSolutions: OPMissionCoreSolutions, setDrillDownTitle: setDrillDownTitle })) : liveContractorDrillDownVisible ? (_jsx(LiveContractorDrillDown, { onBackClick: handleBackClick, title: drillDownTitle, drillDownData: drillDownData, consultingSolutions: LCConsultingSolutions, missionCoreSolutions: LCMissionCoreSolutions, setDrillDownTitle: setDrillDownTitle, totalLiveContractors: (_k = dashboardData === null || dashboardData === void 0 ? void 0 : dashboardData.liveContractor) === null || _k === void 0 ? void 0 : _k.total })) : (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { px: '28px', pt: '10px' } }, { children: _jsx(CommonTabs, { tabs: tabs }) })), _jsxs(Box, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsx(TPCustomModal, { title: 'Terms and Conditions', PaperProps: {
                                    className: classes.paperProps,
                                    sx: { maxWidth: '50rem' },
                                }, slotProps: {
                                    backdrop: { style: { backdropFilter: 'blur(5px)' } },
                                }, cancelText: 'Cancel', confirmText: 'I Accept', open: isTermsAccepted, children: _jsx(MyDocument, { pdf: '../privacy-policy.pdf' }), DialogContentProps: {
                                    className: classes.scrollbar,
                                }, confirmButtonProps: {
                                    className: classes.confirmButton,
                                }, DialogActionsProps: {
                                    className: classes.dialogAction,
                                    sx: { m: '0px 18px', fontSize: fontSizeDynamic(21) },
                                }, checkboxLabel: 'I confirm that I have read and accept the terms and conditions and privacy policy.', onClose: function () {
                                    setIsTermsAccepted(false);
                                    navigate('/');
                                }, checkboxChecked: checkbox, onCheckboxChange: setCheckbox, onConfirm: handleAcceptTerms })] })] })) })) })));
};
export default Home;
