var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Typography, Grid, FormLabel, FormControl, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TPButton from "../../../../tandym-web-common/src/shared/components/button/TPButton";
import TPDropdown from "../../../../tandym-web-common/src/shared/components/dropdown/TPDropdown";
import { TPStyledDrawer, TPStyledBox, } from "../../pages/placements/Placements.styled";
import TPSvgIcon from "../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon";
import { colorCodes } from "../../../../tandym-web-common/src/shared/components/styles/ColorCodesList";
import { SectionTitle } from "../utilities/enumValues";
import { fontSizeDynamic } from "../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils";
var FilterDrawer = function (_a) {
    var title = _a.title, open = _a.open, onClose = _a.onClose, onApply = _a.onApply, startDateFrom = _a.startDateFrom, setStartDateFrom = _a.setStartDateFrom, startDateTo = _a.startDateTo, setStartDateTo = _a.setStartDateTo, endDateFrom = _a.endDateFrom, setEndDateFrom = _a.setEndDateFrom, endDateTo = _a.endDateTo, setEndDateTo = _a.setEndDateTo, fields = _a.fields;
    var renderField = function (field, index) {
        var _a;
        return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { minWidth: "80%" } }, { children: [_jsx(FormLabel, __assign({ className: "formLabel", sx: { fontSize: fontSizeDynamic(16) } }, { children: field.label })), _jsx(TPDropdown, { options: ((_a = field.options) === null || _a === void 0 ? void 0 : _a.map(function (option) { return ({
                            label: option,
                            value: option,
                        }); })) || [], defaultSelectedOption: field.selectedOption, onChange: function (e) { return field.onChange(e.target.value); }, placeholder: "Select ".concat(field.label), name: field.label })] })) }), "dropdown-" + index));
    };
    return (_jsx(TPStyledDrawer, __assign({ anchor: "top", open: open, onClose: onClose }, { children: _jsxs(TPStyledBox, { children: [_jsx(Box, __assign({ sx: { mb: "51px" } }, { children: _jsx(Typography, __assign({ id: "filter-drawer-title", variant: "h6" }, { children: "Filters" })) })), _jsx(Grid, __assign({ container: true, spacing: 4, sx: { mb: "44px" }, id: "filter-drawer" }, { children: fields.map(function (field, index) { return renderField(field, index); }) })), _jsxs(Grid, __assign({ container: true, spacing: 4, sx: { mb: "44px" } }, { children: [_jsx(Grid, __assign({ item: true, md: 12 }, { children: _jsxs(Typography, __assign({ id: "filter-drawer-title", variant: "h6" }, { children: [title === SectionTitle.MyLiveContractors ? "Date" : "Vacancy", " ", "Filter"] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 12 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { width: "67%" } }, { children: [_jsx(FormLabel, __assign({ sx: {
                                            color: "".concat(colorCodes.iconBackgroundColor.royalBlue, " !important"),
                                        } }, { children: "Start Date" })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "From", value: startDateFrom
                                                            ? new AdapterDayjs().date(startDateFrom.toString())
                                                            : null, onChange: function (date) {
                                                            if (setStartDateFrom) {
                                                                setStartDateFrom((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                            }
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                    style: { fontSize: fontSizeDynamic(16) },
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "To", value: startDateTo
                                                            ? new AdapterDayjs().date(startDateTo.toString())
                                                            : null, onChange: function (date) {
                                                            if (setStartDateTo) {
                                                                setStartDateTo((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                            }
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                    style: { fontSize: fontSizeDynamic(16) },
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) }))] })) }))] })) })), title === SectionTitle.MyLiveContractors && (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 12 }, { children: _jsxs(FormControl, __assign({ variant: "standard", sx: { width: "67%" } }, { children: [_jsx(FormLabel, __assign({ sx: {
                                            color: "".concat(colorCodes.iconBackgroundColor.royalBlue, " !important"),
                                        } }, { children: "End Date" })), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "From", value: endDateFrom
                                                            ? new AdapterDayjs().date(endDateFrom.toString())
                                                            : null, onChange: function (date) {
                                                            if (setEndDateFrom) {
                                                                setEndDateFrom((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                            }
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 6 }, { children: _jsx(DatePicker, { label: "To", value: endDateTo
                                                            ? new AdapterDayjs().date(endDateTo.toString())
                                                            : null, onChange: function (date) {
                                                            if (setEndDateTo) {
                                                                setEndDateTo((date === null || date === void 0 ? void 0 : date.toDate()) || null);
                                                            }
                                                        }, slotProps: {
                                                            textField: {
                                                                variant: "standard",
                                                                inputProps: {
                                                                    className: "date-picker",
                                                                    id: "date-picker",
                                                                    "data-testid": "date-picker",
                                                                },
                                                            },
                                                        }, slots: {
                                                            openPickerIcon: function () { return (_jsx(TPSvgIcon, { resourceName: "date-picker-icon", id: "date-picker-icon", "data-testid": "date-picker-icon" })); },
                                                        }, sx: { width: "78%" } }) }))] })) }))] })) })))] })), _jsx(Grid, __assign({ container: true, direction: "row", justifyContent: "end" }, { children: _jsxs(Grid, __assign({ item: true }, { children: [_jsx(TPButton, { children: "Cancel", color: "secondary", onClick: onClose, sx: { mr: "15px", width: "150px", fontSize: fontSizeDynamic(14) } }), _jsx(TPButton, { children: "Apply", color: "primary", onClick: onApply, sx: { width: "150px", fontSize: fontSizeDynamic(14) } })] })) }))] }) })));
};
export default FilterDrawer;
