import { jsx as _jsx } from "react/jsx-runtime";
// router.tsx
import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "../../tandym-web-common/src/pages/ErrorPage";
import Login from "../../tandym-web-common/src/pages/Login";
import Home from "./pages/Home/Home";
import TimesheetsAndExpenses from "../../tandym-web-common/src/pages/timesheets-expenses/TimesheetsAndExpenses";
import Placements from "./pages/placements/Placements";
import { tabs } from "../src/__mocks__/Tabs.mocks";
import { TANDYM_CONSTANTS } from "./shared/Constants";
import ReactGA from "react-ga4";
import { useClientAppDispatch, useClientAppSelector } from "./store/hook";
import { setLogIn } from "./store/authSlice";
import ProtectedRoute from "../../tandym-web-common/src/shared/utilities/ProtectedRoute";
import Faqs from "./pages/Faqs/Faqs";
import InfoCenter from "./pages/infoCenter/InfoCenterMain";
import AboutTandym from "./pages/infoCenter/AboutTandymTab";
import TimeApprovalProcedures from "./pages/infoCenter/TimeApprovalProcedures";
import Contact from "./pages/infoCenter/Contact";
var portal = "client";
var loginURL = TANDYM_CONSTANTS.API_URLS.GENERATE_LOGIN_LINK;
var validateURL = TANDYM_CONSTANTS.API_URLS.LOGIN_LINK_VALIDATION_GET;
var acceptTermsURL = TANDYM_CONSTANTS.API_URLS.ACCEPT_TERMS_POST;
var najaxaURL = TANDYM_CONSTANTS.API_URLS.NAJAXA_API;
var TRACKING_ID = window._env_.REACT_APP_TRACKING_ID_CLIENT ||
    process.env.REACT_APP_TRACKING_ID_CLIENT;
if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID, {
        gaOptions: {
            cookie_flags: 'SameSite=None; Secure',
            anonymizeIp: true, // Masks user IPs for additional privacy
        },
    });
}
var LoginWithRedux = function () {
    var dispatch = useClientAppDispatch();
    var updateEmail = function (email) {
        dispatch(setLogIn({ email: email }));
    };
    var _a = useClientAppSelector(function (state) { return state.userAuth; }), isEmailLinkExpired = _a.isEmailLinkExpired, email = _a.email, isLoggedIn = _a.isLoggedIn;
    if (isLoggedIn && !isEmailLinkExpired) {
        return _jsx(Navigate, { to: "/home" });
    }
    return (_jsx(Login, { portal: portal, loginURL: loginURL, updateEmail: updateEmail, isExpired: isEmailLinkExpired, email: email || "" }));
};
var TermsAndConditionswithRedux = function () {
    var email = useClientAppSelector(function (state) { return state.userAuth; }).email;
    return (_jsx(TimesheetsAndExpenses, { portal: portal, najaxaURL: najaxaURL, email: email }));
};
var ProtectedRouteWrapper = function (_a) {
    var element = _a.element;
    return _jsx(ProtectedRoute, { children: element });
};
var router = createBrowserRouter([
    {
        path: "/",
        element: _jsx(RootLayout, {}),
        errorElement: _jsx(ErrorPage, { portal: portal, tabs: tabs }),
        children: [
            { path: "", element: _jsx(Navigate, { to: "/login" }) },
            {
                path: "login",
                element: _jsx(LoginWithRedux, {}),
            },
            {
                path: "/home",
                element: (_jsx(Home, { validateURL: validateURL, acceptTermsURL: acceptTermsURL })),
            },
            {
                path: "timesheets-expenses",
                element: (_jsx(ProtectedRouteWrapper, { element: _jsx(TermsAndConditionswithRedux, {}) })),
            },
            {
                path: "placements",
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(Placements, {}) }),
            },
            {
                path: "infoCenter",
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(InfoCenter, {}) }),
                children: [
                    {
                        path: "",
                        element: _jsx(Navigate, { to: "aboutTandym" }),
                    },
                    {
                        path: "aboutTandym",
                        element: _jsx(AboutTandym, {}),
                    },
                    {
                        path: "timeApprovalProcedures",
                        element: _jsx(TimeApprovalProcedures, {}),
                    },
                    {
                        path: "contact",
                        element: _jsx(Contact, {}),
                    },
                ],
            },
            {
                path: "faqs",
                element: _jsx(ProtectedRouteWrapper, { element: _jsx(Faqs, {}) }),
            },
        ],
    },
]);
export default router;
