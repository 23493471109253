var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import LiveContractorsAndOpenPositionsContainer from './LiveContractorsAndOpenPositionsContainer';
import { overViewStyles, rightSideBoxStyles, welcomeSectionStyles, } from './OverViewStyles';
import TPSvgIcon from '../.../../../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import TPCarousel from '../../../../../tandym-web-common/src/shared/components/carousel/TPCarousel';
import { CardItems, CarouselCardItems } from './TilesComponent';
import latestTandymPlacements from '../../../../../tandym-web-common/src/images/Latest-Tandym-Placement.png';
import liveContractors from '../../../../../tandym-web-common/src/images/Live-Contractors.png';
import myOpenPositions from '../../../../../tandym-web-common/src/images/My-Open-Positions.png';
import clientHomeWelcomeImage from '../../../images/Image_Home_client.png';
import { circleColorCodes, sectionData } from './overViewPageItems';
import { colorCodes } from '../../../../../tandym-web-common/src/shared/components/styles/ColorCodesList';
import { SectionTitle } from '../../../shared/utilities/enumValues';
import TPProgressModal from '../../../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { functionalGroupName } from '../../../shared/utilities/utilities';
import { fontSizeDynamic } from '../../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var OverView = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var onArrowClick = props.onArrowClick, activePositionData = props.activePositionData, liveContractorData = props.liveContractorData, isLoading = props.isLoading, topTenVacanciesList = props.topTenVacanciesList, availableTandymPositions = props.availableTandymPositions, topTenPlacementsList = props.topTenPlacementsList, callForActionsData = props.callForActionsData, firstName = props.firstName, lastName = props.lastName, clientName = props.clientName;
    var totalActivetConractorData = [
        {
            title: 'Total My Open Jobs',
            totalCount: (_a = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.total) !== null && _a !== void 0 ? _a : 0,
        },
        {
            title: 'Consulting Solutions',
            totalCount: (_b = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.consultantTotal) !== null && _b !== void 0 ? _b : 0,
        },
        {
            title: 'Mission Core Solutions',
            totalCount: (_c = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.missionCoreTotal) !== null && _c !== void 0 ? _c : 0,
        },
    ];
    var totalLiveContractorData = [
        {
            title: 'Total My Live Contractors',
            totalCount: (_d = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.total) !== null && _d !== void 0 ? _d : 0,
        },
        {
            title: 'Consulting Solutions',
            totalCount: (_e = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.consultantTotal) !== null && _e !== void 0 ? _e : 0,
        },
        {
            title: 'Mission Core Solutions',
            totalCount: (_f = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.missionCoreTotal) !== null && _f !== void 0 ? _f : 0,
        },
    ];
    var liveContractorList = (_g = liveContractorData === null || liveContractorData === void 0 ? void 0 : liveContractorData.liveContractorList) !== null && _g !== void 0 ? _g : [];
    var openPositionsList = (_h = activePositionData === null || activePositionData === void 0 ? void 0 : activePositionData.activePositionList) !== null && _h !== void 0 ? _h : [];
    return (_jsxs(Box, __assign({ display: 'flex' }, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsxs(Box, __assign({ sx: { width: '70%', my: 4, ml: 2 } }, { children: [_jsx(Grid, __assign({ container: true, spacing: 2, direction: 'column' }, { children: _jsxs(Box, __assign({ px: 2, sx: [
                                welcomeSectionStyles.welcomeSectionContainer,
                                {
                                    backgroundImage: "url(".concat(clientHomeWelcomeImage, ")"),
                                    mb: '30px',
                                },
                            ] }, { children: [_jsx(Typography, __assign({ sx: { color: 'rgba(255, 255, 255, 0.70)' }, fontSize: fontSizeDynamic(32) }, { children: "Welcome" })), _jsxs(Typography, __assign({ sx: { color: colorCodes.backgroundColors.white, mb: '20px' }, fontSize: fontSizeDynamic(32) }, { children: [firstName, " ", lastName, ", ", clientName] })), _jsx(Typography, __assign({ sx: { color: colorCodes.backgroundColors.white }, fontSize: fontSizeDynamic(16) }, { children: "Connecting Talent with Opportunity" })), _jsx(Typography, __assign({ sx: { color: colorCodes.backgroundColors.white }, fontSize: fontSizeDynamic(16) }, { children: "Here, you can efficiently manage your recruitment activities and monitor key metrics." }))] })) })), _jsx(Grid, __assign({ container: true, spacing: 2, direction: 'column' }, { children: sectionData &&
                            (sectionData === null || sectionData === void 0 ? void 0 : sectionData.map(function (section, index) { return (_jsx(Box, { children: _jsx(LiveContractorsAndOpenPositionsContainer, { id: section.title, headerIcon: section.title === SectionTitle.MyLiveContractors
                                        ? 'client-live-and-active-container-icon'
                                        : 'client-my-open-positions-icon', title: section.title, openPositions: section.title === SectionTitle.MyOpenPositions, totalLiveContractorData: section.title === SectionTitle.MyLiveContractors
                                        ? totalLiveContractorData
                                        : totalActivetConractorData, circleColorCodes: circleColorCodes, liveContractorList: liveContractorList, activePositionList: openPositionsList, bannerImage: section.title === SectionTitle.MyLiveContractors
                                        ? liveContractors
                                        : myOpenPositions, onArrowClick: function (title, data) {
                                        section.title === SectionTitle.MyLiveContractors
                                            ? onArrowClick(SectionTitle.MyLiveContractors, title, data ? data : liveContractorList)
                                            : onArrowClick(SectionTitle.MyOpenPositions, title, data ? data : openPositionsList);
                                    }, topTenVacanciesList: topTenVacanciesList, availableTandymPositionList: availableTandymPositions }) }, index + section.title)); })) }))] })), _jsxs(Box, __assign({ sx: { width: '30%' } }, { children: [_jsxs(Box, __assign({ sx: overViewStyles.smallGridStyle }, { children: [_jsxs(Box, __assign({ sx: [
                                    rightSideBoxStyles.carouseBoxCardImageContainer,
                                    {
                                        height: window.innerWidth > 1800
                                            ? "".concat((window.innerWidth * 10) / 170, "px")
                                            : 'auto',
                                        width: window.innerWidth > 1800
                                            ? "".concat((window.innerWidth * 10) / 36, "px")
                                            : 'auto',
                                        backgroundImage: "url(".concat(latestTandymPlacements, ")"),
                                    },
                                ] }, { children: [_jsxs(Box, __assign({ p: 2, mb: window.innerWidth > 1800 ? 5 : 0 }, { children: [_jsxs(Box, __assign({ sx: overViewStyles.root }, { children: [_jsx(TPSvgIcon, { resourceName: 'tandym-Placements-winning-cup-icon' }), _jsx(Typography, __assign({ sx: rightSideBoxStyles.carouselImageTitle }, { children: "Tandym Placements" }))] })), _jsx(Typography, __assign({ sx: rightSideBoxStyles.carouselImageSubtext }, { children: "Latest placement updates" }))] })), _jsx(Box, { sx: rightSideBoxStyles.imageBorderBottom })] })), _jsx(TPCarousel, { id: 'over-view-carousel', autoPlay: true, stopAutoPlayOnHover: true, fullHeightHover: true, indicatorIconButtonProps: {
                                    color: "".concat(colorCodes.textColors.quillGray),
                                    paddingBottom: '13px',
                                }, activeIndicatorIconButtonProps: {
                                    color: "".concat(colorCodes.iconBackgroundColor.royalBlue),
                                    paddingBottom: '13px',
                                }, children: topTenPlacementsList === null || topTenPlacementsList === void 0 ? void 0 : topTenPlacementsList.map(function (item, index) { return (_jsx(Box, __assign({ sx: { minHeight: '350px' } }, { children: _jsx(CarouselCardItems, { functionalGroupName: functionalGroupName(item.functionalGroupName), MissionCore: item === null || item === void 0 ? void 0 : item.functionalGroupName, description: item === null || item === void 0 ? void 0 : item.functionalGroupDescription, jobTitle: item.jobTitle, city: item === null || item === void 0 ? void 0 : item.city, state: item === null || item === void 0 ? void 0 : item.state, industry: item === null || item === void 0 ? void 0 : item.industry }) }), index + (item === null || item === void 0 ? void 0 : item.functionalGroupName))); }) })] })), _jsx(CardItems, { callForActions: callForActionsData })] }))] })));
};
export default OverView;
