var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../../tandym-web-common/src/components/Header";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useClientAppSelector } from "../store/hook";
import TPProgressModal from "../../../tandym-web-common/src/shared/components/modal/TPProgressModal";
import { useClientAppDispatch } from "../store/hook";
import { setLogOut } from "../store/authSlice";
var RootLayout = function (props) {
    var location = useLocation();
    var dispatch = useClientAppDispatch();
    var _a = useState([]), tabs = _a[0], setTabs = _a[1];
    var entitlementsData = useClientAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var logoutHandler = function () {
        dispatch(setLogOut());
    };
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        if (location.pathname.includes("/home")) {
            setLoading(true);
            setTimeout(function () {
                setLoading(false);
            }, 3000);
        }
        if (entitlementsData.entitlementDto) {
            setTabs([
                { label: "Home", path: "/home", show: true },
                {
                    label: "Placements",
                    path: "/placements",
                    show: entitlementsData.entitlementDto.placement,
                },
                {
                    label: "Time and Expenses",
                    path: "/timesheets-expenses",
                    show: entitlementsData.entitlementDto.timesheets,
                },
                {
                    label: "Info Center",
                    path: "/infoCenter",
                    show: entitlementsData.entitlementDto.infocenter,
                },
                {
                    label: "",
                    path: "/faqs",
                    show: entitlementsData.entitlementDto.faqs,
                },
            ]);
        }
    }, [entitlementsData]);
    return (_jsxs(Box, { children: [location.pathname !== "/login" && (_jsx(Header, { portal: "client", headerTabs: tabs, setLogout: logoutHandler })), _jsx(TPProgressModal, { isLoading: loading }), _jsx(Box, __assign({ component: "main", sx: {
                    flexGrow: 1,
                } }, { children: _jsx(Outlet, {}) }))] }));
};
export default RootLayout;
