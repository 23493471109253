import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// Example data based on the provided table
var placementData = [
    {
        monthCreated: '3/24',
        positions: 1,
        placements: 1,
        percentFilled: 100.0,
    },
    {
        monthCreated: '5/24',
        positions: 6,
        placements: 5,
        percentFilled: 83.3,
    },
    {
        monthCreated: '6/24',
        positions: 5,
        placements: 1,
        percentFilled: 20.0,
    },
    {
        monthCreated: '8/24',
        positions: 1,
        placements: 1,
        percentFilled: 100.0,
    },
];
var PlacedPositions = function (props) {
    var placedPositionsMetrics = props.placedPositionsMetrics;
    // Extract data for Highcharts configuration
    var categories = placedPositionsMetrics.data.map(function (item) { return item.monthCreated; });
    var submittedData = placedPositionsMetrics.data.map(function (item) { return item.positions; });
    var placementsData = placedPositionsMetrics.data.map(function (item) { return item.placements; });
    var filledPercentData = placedPositionsMetrics.data.map(function (item) { return item.filledPercentage; });
    // Highcharts configuration
    var options = {
        chart: {
            zooming: {
                type: 'xy',
            },
        },
        title: {
            text: '',
        },
        xAxis: [
            {
                categories: categories,
                crosshair: true,
            },
        ],
        yAxis: [
            {
                // Primary yAxis
                labels: {
                    format: '{value}',
                },
                title: {
                    text: '',
                },
            },
            {
                // Secondary yAxis for percentage
                title: {
                    text: '',
                },
                labels: {
                    format: '{value}%',
                },
                opposite: true,
            },
        ],
        tooltip: {
            shared: true,
        },
        legend: {
            layout: 'horizontal',
            align: 'right',
            verticalAlign: 'top',
            backgroundColor: '#FFFFFF',
            symbolHeight: 10,
            symbolWidth: 10,
            symbolRadius: 5,
        },
        credits: {
            enabled: false, // Removes the Highcharts watermark
        },
        exporting: {
            enabled: false, // Disable the export menu (hamburger menu)
        },
        plotOptions: {
            column: {
                borderRadius: 0, // Remove rounded corners for sharp edges
            },
        },
        series: [
            {
                name: 'Submitted',
                type: 'column',
                yAxis: 0,
                data: submittedData,
                tooltip: {
                    valueSuffix: '',
                },
                color: '#2A70EA',
            },
            {
                name: 'Placements',
                type: 'column',
                yAxis: 0,
                data: placementsData,
                tooltip: {
                    valueSuffix: '',
                },
                color: '#DDE3EE',
            },
            {
                name: '% Filled',
                type: 'line',
                yAxis: 1,
                data: filledPercentData,
                tooltip: {
                    valueSuffix: '%',
                },
                color: '#FF730E',
                marker: {
                    enabled: true,
                },
            },
        ],
    };
    return _jsx(HighchartsReact, { highcharts: Highcharts, options: options });
};
export default PlacedPositions;
